<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="jobBussiness"
        >
          <label for="jobBussiness">Số doanh nghiệp dịch vụ việc làm đang hoạt động<span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="jobBussiness"
            rules="required"
            :custom-messages="jobBussiness"
          >
            <b-form-input
              v-model="dataInput.jobBussiness"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider></b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="jobBussinessBranch"
        >
          <label for="jobBussinessBranch">Số chi nhánh đang hoạt động dịch vụ việc làm<span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="jobBussinessBranch"
            rules="required"
            :custom-messages="jobBussinessBranch"
          >
            <b-form-input
              v-model="dataInput.jobBussinessBranch"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider></b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="jobBussinessLicensed"
        >
          <label for="jobBussinessLicensed">Số doanh nghiệp được cấp phép<span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="jobBussinessLicensed"
            rules="required"
            :custom-messages="jobBussinessLicensed"
          >
            <b-form-input
              v-model="dataInput.jobBussinessLicensed"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider></b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="code"
        >
          <label for="code">Số doanh nghiệp được gia hạn giấy phép<span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="jobBussinessExtend"
            rules="required"
            :custom-messages="jobBussinessExtend"
          >
            <b-form-input
              v-model="dataInput.jobBussinessExtend"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider></b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="code"
        >
          <label for="code">Số doanh nghiệp được cấp lại giấy phép<span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="jobBussinessRenew"
            rules="required"
            :custom-messages="jobBussinessRenew"
          >
            <b-form-input
              v-model="dataInput.jobBussinessRenew"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider></b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="code"
        >
          <label for="code">Số doanh nghiệp thu hồi giấy phép<span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="jobBussinessRevoke"
            rules="required"
            :custom-messages="jobBussinessRevoke"
          >
            <b-form-input
              v-model="dataInput.jobBussinessRevoke"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider></b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dataInput: {
      type: Object,
      default: _ => {},

    },
  },
  data() {
    return {
      required,
      jobBussiness: {
        required: 'Số doanh nghiệp dịch vụ việc làm đang hoạt động là bắt buộc',
      },
      jobBussinessBranch: {
        required: 'Số chi nhánh đang hoạt động dịch vụ việc làm là bắt buộc',
      },
      jobBussinessLicensed: {
        required: 'Số doanh nghiệp được cấp phép là bắt buộc',
      },
      jobBussinessExtend: {
        required: 'Số doanh nghiệp được gia hạn giấy phép là bắt buộc',
      },
      jobBussinessRenew: {
        required: 'Số doanh nghiệp được cấp lại giấy phép là bắt buộc',
      },
      jobBussinessRevoke: {
        required: 'Số doanh nghiệp thu hồi giấy phép là bắt buộc',
      },
    }
  },
  methods: {

  },
}
</script>
