<template>
  <b-modal
    :id="id"
    size="sm"
    title="Cập nhật thông tin báo cáo"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Cập nhật"
    centered
    @ok="accept($event)"
    @hidden="resetData"
  >

    <div class="addBusiness-fix ">
      <my-tabs
        :listTabs="listTabs"
        defaultActive="user-edit-tab-account"
        typeCss="active-full"
        @setActive="setActive"
      >
      </my-tabs>
      <validation-observer ref="tabAccount">
        <info-equip
          ref="info"
          :dataInput="dataInput"
          class="tab-pane"
          :class="{'active':activeItem==='info'}"
        />
        <service
          :dataInput="dataInput"
          class="tab-pane"
          :class="{'active':activeItem==='service'}"
        />
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import InfoEquip from './tab-edit/Info.vue'
import Service from './tab-edit/Service.vue'

export default {
  components: {
    BButton,
    MyTabs,
    InfoEquip,
    ValidationProvider,
    ValidationObserver,
    Service,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      dataInput: {
        jobCenter: '',
        jobCenterIncrease: '',
        jobCenterIncreaseContent: '',
        jobCenterDecrease: '',
        jobCenterDecreaseContent: '',
        jobCenterChange: '',
        jobCenterChangeContent: '',
        jobBussiness: '',
        jobBussinessBranch: '',
        jobBussinessLicensed: '',
        jobBussinessExtend: '',
        jobBussinessRenew: '',
        jobBussinessRevoke: '',
      },
      activeItem: 'info',
      detailId: '',
      listTabs: [
        {
          key: 'info',
          title: 'Trung tâm dịch vụ việc làm',
          icon: 'BriefcaseIcon',
          isRendered: false,
        },
        {
          key: 'service',
          title: 'Doanh nghiệp dịch vụ việc làm',
          icon: 'UserIcon',
          isDisabled: false,
        },
      ],
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          jobCenter: this.dataDetail.jobCenter,
          jobCenterIncrease: this.dataDetail.jobCenterIncrease,
          jobCenterIncreaseContent: this.dataDetail.jobCenterIncreaseContent,
          jobCenterDecrease: this.dataDetail.jobCenterDecrease,
          jobCenterDecreaseContent: this.dataDetail.jobCenterDecreaseContent,
          jobCenterChange: this.dataDetail.jobCenterChange,
          jobCenterChangeContent: this.dataDetail.jobCenterChangeContent,
          jobBussiness: this.dataDetail.jobBussiness,
          jobBussinessBranch: this.dataDetail.jobBussinessBranch,
          jobBussinessLicensed: this.dataDetail.jobBussinessLicensed,
          jobBussinessExtend: this.dataDetail.jobBussinessExtend,
          jobBussinessRenew: this.dataDetail.jobBussinessRenew,
          jobBussinessRevoke: this.dataDetail.jobBussinessRevoke,
        }
      }
    },

  },

  created() {
  },

  methods: {
    setActive(tabActive) {
      this.activeItem = tabActive
    },
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.tabAccount.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
      this.activeItem = 'info'
    },
    resetData() {
      this.dataInput = {
        jobCenter: '',
        jobCenterIncrease: '',
        jobCenterIncreaseContent: '',
        jobCenterDecrease: '',
        jobCenterDecreaseContent: '',
        jobCenterChange: '',
        jobCenterChangeContent: '',
        jobBussiness: '',
        jobBussinessBranch: '',
        jobBussinessLicensed: '',
        jobBussinessExtend: '',
        jobBussinessRenew: '',
        jobBussinessRevoke: '',
      }
    },
  },
}
</script>

<style lang="scss">
#modal-edit-report{
  .modal-dialog{
    max-width: 1126px;
  }
  .page-container{
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 0%),
  }
  .tab-container{
    margin-bottom: 0,
  }
}
</style>
