<template>
  <div>
    <b-col>
      <b-form-group
        label-for="jobCenter"
      >
        <label for="jobCenter">Số trung tâm dịch vụ việc làm<span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          name="jobCenter"
          rules="required"
          :custom-messages="jobCenter"
        >
          <b-form-input
            v-model="dataInput.jobCenter"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label-for="jobCenterIncrease"
      >
        <label for="jobCenterIncrease">Số trung tâm tăng<span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          name="jobCenterIncrease"
          rules="required"
          :custom-messages="jobCenterIncrease"
        >
          <b-form-input
            v-model="dataInput.jobCenterIncrease"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider></b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label-for="jobCenterIncreaseContent"
      >
        <label for="jobCenterIncreaseContent">Nội dung trung tâm tăng<span class="text-danger">(*)</span></label>
        <b-form-textarea
          v-model="dataInput.jobCenterIncreaseContent"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label-for="jobCenterDecrease"
      >
        <label for="jobCenterDecrease">Số trung tâm giảm<span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          name="jobCenterDecrease"
          rules="required"
          :custom-messages="jobCenterDecrease"
        >
          <b-form-input
            v-model="dataInput.jobCenterDecrease"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>

        </validation-provider></b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label-for="jobCenterDecreaseContent"
      >
        <label for="jobCenterDecreaseContent">Nội dung trung tâm giảm<span class="text-danger">(*)</span></label>

        <b-form-textarea
          v-model="dataInput.jobCenterDecreaseContent"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label-for="jobCenterChange"
      >
        <label for="jobCenterChange">Số trung tâm tổ chức lại<span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          name="jobCenterChange"
          rules="required"
          :custom-messages="jobCenterChange"
        >
          <b-form-input
            v-model="dataInput.jobCenterChange"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>

        </validation-provider></b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label-for="jobCenterChangeContent"
      >
        <label for="jobCenterChangeContent">Nội dung trung tâm tổ chức lại<span class="text-danger">(*)</span></label>
        <b-form-textarea
          v-model="dataInput.jobCenterChangeContent"
        />
      </b-form-group>
    </b-col>

  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    DateTimePicker,
    ValidationProvider,
    BFormTextarea,
    ValidationObserver,
  },
  props: {
    dataInput: {
      type: Object,
      default: _ => {},

    },
  },
  data() {
    return {
      required,
      jobCenter: {
        required: 'Số trung tâm là bắt buộc',
      },
      jobCenterIncrease: {
        required: 'Số trung tâm tăng là bắt buộc',
      },
      jobCenterDecrease: {
        required: 'Số trung tâm giảm là bắt buộc',
      },
      jobCenterChange: {
        required: 'Số trung tâm tổ chức lại là bắt buộc',
      },

    }
  },
  methods: {

  },
}
</script>
