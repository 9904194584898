var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"jobBussiness"}},[_c('label',{attrs:{"for":"jobBussiness"}},[_vm._v("Số doanh nghiệp dịch vụ việc làm đang hoạt động"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"jobBussiness","rules":"required","custom-messages":_vm.jobBussiness},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.jobBussiness),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobBussiness", $$v)},expression:"dataInput.jobBussiness"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"jobBussinessBranch"}},[_c('label',{attrs:{"for":"jobBussinessBranch"}},[_vm._v("Số chi nhánh đang hoạt động dịch vụ việc làm"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"jobBussinessBranch","rules":"required","custom-messages":_vm.jobBussinessBranch},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.jobBussinessBranch),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobBussinessBranch", $$v)},expression:"dataInput.jobBussinessBranch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"jobBussinessLicensed"}},[_c('label',{attrs:{"for":"jobBussinessLicensed"}},[_vm._v("Số doanh nghiệp được cấp phép"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"jobBussinessLicensed","rules":"required","custom-messages":_vm.jobBussinessLicensed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.jobBussinessLicensed),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobBussinessLicensed", $$v)},expression:"dataInput.jobBussinessLicensed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Số doanh nghiệp được gia hạn giấy phép"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"jobBussinessExtend","rules":"required","custom-messages":_vm.jobBussinessExtend},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.jobBussinessExtend),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobBussinessExtend", $$v)},expression:"dataInput.jobBussinessExtend"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Số doanh nghiệp được cấp lại giấy phép"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"jobBussinessRenew","rules":"required","custom-messages":_vm.jobBussinessRenew},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.jobBussinessRenew),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobBussinessRenew", $$v)},expression:"dataInput.jobBussinessRenew"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('label',{attrs:{"for":"code"}},[_vm._v("Số doanh nghiệp thu hồi giấy phép"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"jobBussinessRevoke","rules":"required","custom-messages":_vm.jobBussinessRevoke},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.jobBussinessRevoke),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobBussinessRevoke", $$v)},expression:"dataInput.jobBussinessRevoke"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }