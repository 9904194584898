var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"jobCenter"}},[_c('label',{attrs:{"for":"jobCenter"}},[_vm._v("Số trung tâm dịch vụ việc làm"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"jobCenter","rules":"required","custom-messages":_vm.jobCenter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.jobCenter),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobCenter", $$v)},expression:"dataInput.jobCenter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"jobCenterIncrease"}},[_c('label',{attrs:{"for":"jobCenterIncrease"}},[_vm._v("Số trung tâm tăng"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"jobCenterIncrease","rules":"required","custom-messages":_vm.jobCenterIncrease},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.jobCenterIncrease),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobCenterIncrease", $$v)},expression:"dataInput.jobCenterIncrease"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"jobCenterIncreaseContent"}},[_c('label',{attrs:{"for":"jobCenterIncreaseContent"}},[_vm._v("Nội dung trung tâm tăng"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('b-form-textarea',{model:{value:(_vm.dataInput.jobCenterIncreaseContent),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobCenterIncreaseContent", $$v)},expression:"dataInput.jobCenterIncreaseContent"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"jobCenterDecrease"}},[_c('label',{attrs:{"for":"jobCenterDecrease"}},[_vm._v("Số trung tâm giảm"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"jobCenterDecrease","rules":"required","custom-messages":_vm.jobCenterDecrease},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.jobCenterDecrease),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobCenterDecrease", $$v)},expression:"dataInput.jobCenterDecrease"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"jobCenterDecreaseContent"}},[_c('label',{attrs:{"for":"jobCenterDecreaseContent"}},[_vm._v("Nội dung trung tâm giảm"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('b-form-textarea',{model:{value:(_vm.dataInput.jobCenterDecreaseContent),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobCenterDecreaseContent", $$v)},expression:"dataInput.jobCenterDecreaseContent"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"jobCenterChange"}},[_c('label',{attrs:{"for":"jobCenterChange"}},[_vm._v("Số trung tâm tổ chức lại"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"jobCenterChange","rules":"required","custom-messages":_vm.jobCenterChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.jobCenterChange),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobCenterChange", $$v)},expression:"dataInput.jobCenterChange"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"jobCenterChangeContent"}},[_c('label',{attrs:{"for":"jobCenterChangeContent"}},[_vm._v("Nội dung trung tâm tổ chức lại"),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('b-form-textarea',{model:{value:(_vm.dataInput.jobCenterChangeContent),callback:function ($$v) {_vm.$set(_vm.dataInput, "jobCenterChangeContent", $$v)},expression:"dataInput.jobCenterChangeContent"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }